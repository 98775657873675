
import { defineComponent, ref, onBeforeMount } from "vue";
import { ApiBase } from "@/core/api";
import { useI18n } from "vue-i18n";

interface Filter {
  service_provider_name: string;
  service_provider_short_key: string;
  service_provider_based_country: string;
  service_provider_coverage: string;
  service_provider_services: string;
  service_provider_customs_terms: Array<string>;
}

export default defineComponent({
  name: "filter-warehouses-dropdown",
  components: {},
  emits: ["submit-filter", "reset-filter"],
  setup(props, { emit }) {
    const { t } = useI18n();

    const formRef = ref<null | HTMLFormElement>(null);
    const formData = ref<Filter>({
      service_provider_name: "",
      service_provider_short_key: "",
      service_provider_based_country: "",
      service_provider_coverage: "",
      service_provider_services: "",
      service_provider_customs_terms: [],
    });

    const options = ref({
      service_provider_based_country: [],
      service_provider_coverage: [],
      service_provider_services: [],
      service_provider_customs_terms: [],
    });

    const submit = () => {
      emit("submit-filter", formData.value);
    };

    const handleReset = () => {
      formRef.value?.resetFields();
      emit("reset-filter");
    };

    const getCountryData = async () => {
      const { data } = await ApiBase.getCountryData();
      if (data.code == 0) {
        options.value.service_provider_based_country = data.data;
      }
    };

    const getTaggingData = async () => {
      const { data } = await ApiBase.getTaggingData({
        short_key: [
          "logistics_service_provider_coverage",
          "logistics_service_provider_services",
          "logistics_service_provider_customs_terms",
        ],
      });
      if (data.code == 0) {
        options.value.service_provider_coverage =
          data.data.logistics_service_provider_coverage.items;
        options.value.service_provider_customs_terms =
          data.data.logistics_service_provider_customs_terms.items;
        options.value.service_provider_services =
          data.data.logistics_service_provider_services.items;
        // options.value.taggings = data.data.warehouse_taggings.items;
      }
    };

    const getFromInfo = async () => {
      Promise.all([getCountryData(), getTaggingData()])
        .then(() => {
          // loading.value = false;
        })
        .catch((error) => {
          console.log(error);
        });
    };

    onBeforeMount(() => {
      getFromInfo();
    });

    return {
      t,
      formData,
      formRef,
      options,
      submit,
      handleReset,
    };
  },
});
