
import { defineComponent, ref, onBeforeMount } from "vue";
import { hideModal } from "@/core/helpers/dom";
import { ApiBase, ApiLogisticsServiceProvider } from "@/core/api";
// import Swal from "sweetalert2/dist/sweetalert2.js";
import mixin from "@/mixins";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "add-logistics-service-provider",
  components: {},
  emits: ["update-list", "reset-form"],
  setup(props, { emit }) {
    const { t } = useI18n();

    const formRef = ref<null | HTMLFormElement>(null);
    const addLogisticsServiceProvider = ref<null | HTMLElement>(null);
    const submitButton = ref<HTMLElement | null>(null);
    const loading = ref<boolean>(false);
    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();
    const options = ref({
      service_provider_based_country: [],
      service_provider_coverage: [],
      service_provider_services: [],
      service_provider_customs_terms: [],
    });
    const formData = ref({
      status: 10,
      service_provider_name: "",
      service_provider_short_key: "",
      service_provider_based_country: "",
      service_provider_coverage: "",
      service_provider_services: "",
      service_provider_customs_terms: "",
    });

    const countryOptions = ref([]);
    const parentCompanys = ref([]);
    const rules = ref({
      status: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      service_provider_name: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      service_provider_short_key: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      service_provider_based_country: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      service_provider_coverage: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
    });

    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate((valid) => {
        if (valid) {
          if (loading.value) return;
          loading.value = true;
          if (submitButton.value) {
            submitButton.value.setAttribute("data-kt-indicator", "on");
          }
          ApiLogisticsServiceProvider.addLogisticsServiceProvider(
            formData.value
          )
            .then(({ data }) => {
              loading.value = false;
              submitButton.value?.removeAttribute("data-kt-indicator");
              if (data.code == 0) {
                showFormSubmitSuccessMsg(() => {
                  resetForm();
                  hideModal(addLogisticsServiceProvider.value);
                  emit("update-list");
                });
              } else {
                showServerErrorMsg(data);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const getCountryData = async () => {
      const { data } = await ApiBase.getCountryData();
      if (data.code == 0) {
        options.value.service_provider_based_country = data.data;
      }
    };

    const getTaggingData = async () => {
      const { data } = await ApiBase.getTaggingData({
        short_key: [
          "logistics_service_provider_coverage",
          "logistics_service_provider_services",
          "logistics_service_provider_customs_terms",
        ],
      });
      if (data.code == 0) {
        options.value.service_provider_coverage =
          data.data.logistics_service_provider_coverage.items;
        options.value.service_provider_customs_terms =
          data.data.logistics_service_provider_customs_terms.items;
        options.value.service_provider_services =
          data.data.logistics_service_provider_services.items;
        // options.value.taggings = data.data.warehouse_taggings.items;
      }
    };

    const getFromInfo = async () => {
      loading.value = true;
      Promise.all([getCountryData(), getTaggingData()])
        .then(() => {
          loading.value = false;
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const handleDiscard = () => {
      hideModal(addLogisticsServiceProvider.value);
      resetForm();
    };

    const resetForm = () => {
      formRef.value?.resetFields();
    };

    onBeforeMount(() => {
      getFromInfo();
    });

    return {
      t,
      loading,
      formData,
      rules,
      formRef,
      addLogisticsServiceProvider,
      submitButton,
      countryOptions,
      parentCompanys,
      options,
      submit,
      handleDiscard,
      resetForm,
    };
  },
});
